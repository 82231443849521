<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card
          border-variant="primary"
          header="Seleccione items"
          header-bg-variant="primary"
          header-text-variant="white"
          class="mb-10"
        >
          <b-card-text>
            <div class="row">
              <b-alert show variant="warning" style="width: 100%;"
                >En caso de varias promos sobre un item al mismo tiempo, se toma
                en cuenta el más reciente</b-alert
              >
            </div>
            <div class="row mb-5">
              <div class="col-md-5 col-sm-12">
                <label class="typo__label">Especialidad</label>
                <multiselect
                  v-model="form.specialty"
                  deselect-label="No puedes remover este valor"
                  track-by="id"
                  label="name"
                  placeholder="Selecciona una especialidad"
                  :options="specialties"
                  :searchable="true"
                  :allow-empty="false"
                  @select="getServices"
                >
                </multiselect>
              </div>
              <div class="col-md-5 col-sm-12">
                <label class="typo__label">Servicios</label>
                <multiselect
                  v-model="form.service"
                  deselect-label="No puedes remover este valor"
                  track-by="id"
                  label="name"
                  placeholder="Selecciona una especialidad"
                  :options="services"
                  :searchable="true"
                  :allow-empty="false"
                >
                </multiselect>
              </div>
              <div class="col-md-2 col-sm-12">
                <label class="typo__label">Desc. (%)</label>
                <b-input
                  type="number"
                  v-model="form.discount"
                  step="0.01"
                ></b-input>
              </div>
              <div class="col-md-6 col-sm-12">
                <label class="typo__label">Desde</label>
                <b-input type="datetime-local" v-model="form.start"></b-input>
              </div>
              <div class="col-md-6 col-sm-12">
                <label class="typo__label">Hasta</label>
                <b-input type="datetime-local" v-model="form.end"></b-input>
              </div>
            </div>
          </b-card-text>
          <template #footer>
            <b-button variant="success" @click="savePromo" style="float: right"
              >Crear
            </b-button>
          </template>
        </b-card>
        <b-card>
          <b-card-text>
            <div class="row mb-5">
              <b-table :fields="fields" :items="promos">
                <template #cell(original)="data">
                  {{ data.item.original.toFixed(2) }}
                </template>
                <template #cell(discounted)="data">
                  {{ data.item.price.toFixed(2) }}
                </template>
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class="mb-2"
                    variant="danger"
                    v-b-tooltip.hover
                    @click="removePromo(data.item)"
                  >
                    X
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

export default {
  name: "ListAppointment",
  components: {
    Multiselect
  },
  data() {
    return {
      fields: [
        { label: "Item", key: "name" },
        { label: "Desde", key: "start" },
        { label: "Hasta", key: "end" },
        { label: "Dcnto (%)", key: "discount" },
        { label: "Original", key: "original" },
        { label: "Con descuento", key: "discounted" },
        { label: "Acciones", key: "actions" }
      ],
      form: {
        start: "",
        end: "",
        specialty: null,
        service: null,
        discount: 1.0
      },
      specialties: [],
      services: [],
      promos: []
    };
  },
  methods: {
    removePromo(promo) {
      ApiService.delete(`promo/${promo.id}/`).then(res => {
        console.log(res);
        this.getPromos();
      });
    },
    getServices(selectedOption) {
      this.services = [];
      this.form.service = null;
      ApiService.get("service", `?specialty=${selectedOption.id}`).then(res => {
        this.services = res.data;
      });
    },
    savePromo() {
      if (this.form.specialty === null) {
        alert("Debes escoger al menos una especialidad");
      }
      if (this.form.service === null) {
        this.form.content_type = 12;
        this.form.object_id = this.form.specialty.id;
      } else {
        this.form.content_type = 19;
        this.form.object_id = this.form.service.id;
      }
      ApiService.post("promo/", this.form).then(res => {
        console.log(res.data);
        this.getPromos();
      });
    },
    getSpecialties() {
      ApiService.get("specialty", "?is_auxiliary=1").then(res => {
        this.specialties = res.data;
      });
    },
    makeToast(message = null, variant = null) {
      this.$bvToast.toast(message, {
        title: `Todo bien`,
        variant: variant,
        solid: true
      });
    },
    getCurrentDateTimeFormat() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      let hh = String(today.getHours()).padStart(2, "0");
      let mins = String(today.getMinutes()).padStart(2, "0");

      let formatted = `${yyyy}-${mm}-${dd}T${hh}:${mins}`;
      console.log(formatted);
      this.form.start = formatted;
      this.form.end = formatted;
    },
    getPromos() {
      ApiService.get("promo").then(res => {
        this.promos = res.data;
      });
    }
  },
  mounted() {
    this.getSpecialties();
    this.getPromos();
    this.getCurrentDateTimeFormat();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Promociones" },
      { title: "Crear promoción" }
    ]);
  }
};
</script>

<style scoped>
.card-header {
  padding: 1rem 2.25rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
